import React from 'react';
import '../styles/About.scss';
import {Helmet} from 'react-helmet';
import toka from '../assets/hienous_sub.png';


class About extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta property="og:title" content="Main" />
                    <meta content="image/png" property="og:image:type" />
                    <meta content="1200" property="og:image:width" />
                    <meta content="630" property="og:image:height" />
                    <meta property="og:image" content={toka} />
                    <meta content="website" property="og:type" />
                    <meta content="Hienous About" property="og:title" />
                    <meta content="Hienous Sub About" property="og:description" />
                </Helmet>
                <div className="wrapper">
                    <h1>this is the about page</h1>
                </div>
            </React.Fragment>
        );
    }
}

export default About;
