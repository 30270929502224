import React from 'react';
import '../styles/Content.scss';

class Content extends React.Component {

    render() {
        return(
            <div className="wrapper">
                <h1>this is the content page</h1>
            </div>
        );
    }
}

export default Content;
