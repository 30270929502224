import React from 'react';
import '../styles/Home.scss';

import 'leaflet/dist/leaflet.css';
import {MapContainer, TileLayer} from 'react-leaflet';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
    }


    printCookie(cookies) {
        return cookies.reduce((acc, curr, index) => {
            if (curr !== '') {
                const rawString = curr.trim();
                const beforeValuesIndex = rawString.search(/=/);

                acc.push(<li key={index}>{rawString.slice(0, beforeValuesIndex)}</li>);
            }
            return acc;
        }, []);
    }

    buttonClick = () => {
        this.setState({toggle: !this.state.toggle});
    }


    render() {
        const documentCookies = document.cookie.split(';');
        const cookieP = this.printCookie(documentCookies);
        const headerText = cookieP.length > 0 ? 'Cookies' : 'Home';
        return (
            <div className="wrapper">
                <h1>{headerText}</h1>
                <ul>
                    {cookieP}
                </ul>
                <div style={{margin: '0 auto'}}>
                    <button onClick={this.buttonClick}>REFRESH</button>
                </div>
                <div className="map">
                    <MapContainer center={[60.451799,22.266414]} zoom={13}>
                        <TileLayer url="https://maptiles.turku.fi/styles/hel-osm-bright/{z}/{x}/{y}.png" />
                    </MapContainer>
                </div>

            </div>
        )
    }
}

export default Home;
