import {Switch, Route, withRouter} from 'react-router-dom';
import Home from './views/Home';
import Content from './views/Content';
import Contact from './views/Contact';
import About from './views/About';

const Routes = () => {
    return (
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/content" component={Content}/>
                <Route path="/about" component={About}/>
                <Route path="/contact" component={Contact}/>
            </Switch>
        )

}

export default Routes;
