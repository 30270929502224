import React from 'react';
import './App.scss';
import {BrowserRouter} from 'react-router-dom';
import Routes from './Routes';
import Header from './components/Header';
import Footer from './components/Footer';
import {Helmet} from 'react-helmet';
import ekaLogo from './assets/hienous_main.png';
class App extends React.Component {
    constructor(props) {
        super(props);
    }
    checkCookieHub() {
        const rawCookies = document.cookie.split(';');
        if (rawCookies.some((item) => item.trim().startsWith('cookiehub'))) {
            const necessaryCookies = window.cookiehub.hasConsented('necessary');
            const analyticsCookies = window.cookiehub.hasConsented('analytics');
            const preferencesCookies = window.cookiehub.hasConsented('preferences');

            if (necessaryCookies && !rawCookies.some((item) => item.trim().startsWith('hienousConsent='))) {
                const expiration = new Date();
                expiration.setDate(expiration.getDate() + 10);
                console.log('hienousConsent was not found, setting cookie..')
                document.cookie = `hienousConsent=true; expires=${expiration.toUTCString()}`;
            }

            if (analyticsCookies && rawCookies.some((item) => item.trim().startsWith('analyticsHienousCookiezz='))) {
                const expiration = new Date();
                expiration.setDate(expiration.getDate() + 20);
                console.log('analyticsHienousCookie was not found, setting cookie..')
                document.cookie = `analyticsHienousCookie=true; expires=${expiration.toUTCString()}`;
            }

            if (preferencesCookies && rawCookies.some((item) => item.trim().startsWith('optionalHienousCookiezz='))) {
                const expiration = new Date();
                expiration.setDate(expiration.getDate() + 10);
                console.log('optionalHienousCookie was not found, setting cookie..')
                document.cookie = `optionalHienousCookie=true; expires=${expiration.toUTCString()}`;
            }
        }

    }

    checkCookie() {
        const rawCookies = document.cookie.split(';');
        if (rawCookies.some((item) => item.trim().startsWith('CookieConsent='))) {
            console.log('The cookie "CookieConsent" exists');
            const cookies = document.cookie.split('; ').reduce((prev, current) => {
                const [name, ...value] = current.split('=');
                prev[name] = value.join('=');
                return prev;
            }, {});
            if (cookies.CookieConsent && cookies.CookieConsent.includes('preferences:true')) {
                console.log('consented to preferences cookies, placing optionalHienousCookie if missing..');
                if (!rawCookies.some((item) => item.trim().startsWith('optionalHienousCookie='))) {
                    console.log('optionalHienousCookie was not found, setting cookie..')
                    document.cookie = 'optionalHienousCookie=true; expires=Fri, 23 Apr 2021 00:00:00 GMT';
                }
            }
            if (cookies.CookieConsent && cookies.CookieConsent.includes('necessary:true')) {
                console.log('consented to necessary cookies, placing hienousConsent if missing..');
                if (!rawCookies.some((item) => item.trim().startsWith('hienousConsent='))) {
                    console.log('hienousConsent was not found, setting cookie..')
                    document.cookie = 'hienousConsent=true; expires=Fri, 23 Apr 2021 00:00:00 GMT';
                }
            }
            if (cookies.CookieConsent && cookies.CookieConsent.includes('preferences:false')) {
                console.log('not consented to preferences cookies, removing optionalHienousCookie if it exists..');
                if (rawCookies.some((item) => item.trim().startsWith('optionalHienousCookie='))) {
                    console.log('optionalHienousCookie was found, removing..')
                    document.cookie = 'optionalHienousCookie=true; expires=Fri, 23 Apr 2021 00:00:00 GMT';
                }
            }
            if (cookies.CookieConsent && cookies.CookieConsent.includes('necessary:true')) {
                console.log('consented to necessary cookies, placing hienousConsent if missing..');
                if (!rawCookies.some((item) => item.trim().startsWith('hienousConsent='))) {
                    console.log('hienousConsent was not found, setting cookie..')
                    document.cookie = 'hienousConsent=true; expires=Fri, 23 Apr 2021 00:00:00 GMT';
                }
            }
        }
    }

    checkCookieConsent() {
        const rawCookies = document.cookie.split(';');
        const necessaryCookies = window.cookiehub.hasConsented('necessary');
        const analyticsCookies = window.cookiehub.hasConsented('analytics');
        const preferencesCookies = window.cookiehub.hasConsented('preferences');

        if (rawCookies.some((item) => item.trim().startsWith('CookieConsent='))) {
            const cookieObject = window.Cookiebot;
            if (cookieObject.consent.preferences ===  true) {
                console.log('consented to preferences cookies, placing optionalHienousCookie if missing..');
                if (!rawCookies.some((item) => item.trim().startsWith('optionalHienousCookie='))) {
                    const expiration = new Date();
                    expiration.setDate(expiration.getDate() + 10);
                    console.log('optionalHienousCookie was not found, setting cookie..')
                    document.cookie = `optionalHienousCookie=true; expires=${expiration.toUTCString()}`;
                }
            }
            if (cookieObject.consent.necessary === true) {
                console.log('consented to necessary cookies, placing hienousConsent if missing..');
                if (!rawCookies.some((item) => item.trim().startsWith('hienousConsent='))) {
                    const expiration = new Date();
                    expiration.setDate(expiration.getDate() + 10);
                    console.log('hienousConsent was not found, setting cookie..')
                    document.cookie = `hienousConsent=true; expires=${expiration.toUTCString()}`;
                }
            }

        }
        if (!rawCookies.some((item) => item.trim().startsWith('optionalHienousCookie='))) {
            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 10);
            console.log('optionalHienousCookie was not found, setting cookie..')
            document.cookie = `optionalHienousCookie=true; expires=${expiration.toUTCString()}`;
        }

        if (!rawCookies.some((item) => item.trim().startsWith('hienousConsent='))) {
            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 10);
            console.log('hienousConsent was not found, setting cookie..')
            document.cookie = `hienousConsent=true; expires=${expiration.toUTCString()}`;
        }
    }

    getCookieScripts() {
        const scriptString = `
          var _paq = _paq || [];
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
              var u="https://testivaraamo.turku.fi:8003/";
              _paq.push(['setTrackerUrl', u+'piwik.php']);
              _paq.push(['setSiteId', '1']);
              _paq.push(['setVisitorCookieTimeout','7776000']);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.type='text/javascript';
              g.async=true;
              g.defer=true;
              g.src=u+'matomo.js';
              s.parentNode.insertBefore(g,s);
          })();`;

        return (
            <script type='text/javascript'>
                {scriptString}
            </script>
        )
    }
    render() {
        return (
            <BrowserRouter>
                <Helmet>
                    <meta property="og:title" content="Main" />
                    <meta content="image/png" property="og:image:type" />
                    <meta content="1200" property="og:image:width" />
                    <meta content="630" property="og:image:height" />
                    <meta property="og:image" content={ekaLogo} />
                    <meta content="website" property="og:type" />
                    <meta content="Hienous Main" property="og:title" />
                    <meta content="Hienous Main APP" property="og:description" />

                </Helmet>
                <div className="container">
                    <header>
                        <h1>this is header</h1>
                        <Header />
                    </header>
                    <main>
                        <Routes />
                    </main>
                    <footer>
                        <Footer />
                    </footer>
                </div>
            </BrowserRouter>



        );
    }

}

export default App;
