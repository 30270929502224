import React from 'react';
import '../styles/Contact.scss';

class Contact extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <h1>this is the contact page</h1>
            </div>
        );
    }
}

export default Contact;
